import React from 'react';
import './App.css';
import presentationGif from './presentation.gif';
import presentationMobileGif from './logo.png';

const App: React.FC = () => {
  return (
    <div className="App">
      <img src={presentationGif} alt="Presentation" className="desktop-image" />
      <img src={presentationMobileGif} alt="Presentation Mobile" className="mobile-image" />
      <footer className="overlay">
        <h1>QuParadox</h1>
      </footer>
      <div className="overlay">
        <p>is coming soon ...</p>
      </div>
    </div>
  );
}

export default App;